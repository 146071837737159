<template>
    <b-overlay :show="loading">
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" v-if="allowCreate()" @click.prevent="add">Tambah</b-button>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive :fields="fields" :items="pengadaans">
                        <template #cell(order)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(informasi.total_pembelian)="{item}">
                            Rp {{ item.informasi ? formatRupiah(item.informasi.total_pembelian) : '' }}
                        </template>
                        <template #cell(status)="{item}">
                            <div class="d-flex justify-content-center">
                                <b-badge :variant="item.status_variant">{{ item.status_ket ? capitalize(item.status_ket) : 'Menunggu persetujuan Owner' }}</b-badge>
                            </div>
                        </template>
                        <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <b-button
                                    size="sm"
                                    @click="$router.push(`/asset-pengadaan/detail/${item.id}`)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowUpdate() && item.selesai == 0 && item.status == 0"
                                    size="sm"
                                    @click.prevent="edit(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button
                                    v-if="(allowDelete() && item.selesai == 0 && item.status == 0)"
                                    size="sm"
                                    @click="remove(item)"
                                    class="mr-1"
                                    variant="outline-danger"
                                    >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
        <!-- Modal create -->
        <b-modal id="form-pengadaan" centered size="lg" title="Form Procurement Aset">
            <validation-observer ref="form-pengadaan">
                <b-form-group label="Supplier">
                    <validation-provider
                        #default="{ errors }"
                        name="supplier"
                        rules="required"
                    >
                        <v-select v-model="form.id_supplier" :options="suppliers" :reduce="option => option.value" label="text"></v-select>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Procurement Date">
                    <validation-provider
                        #default="{ errors }"
                        name="tanggal"
                        rules="required"
                    >
                        <b-form-input type="date" v-model="form.tanggal"></b-form-input>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Keterangan">
                    <b-form-textarea v-model="form.keterangan"></b-form-textarea>
                </b-form-group>
            </validation-observer>
            <template #modal-footer>
                <b-button @click.prevent="submit" variant="primary">Simpan</b-button>
            </template>
        </b-modal>
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BOverlay, BFormTextarea, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BOverlay, BFormTextarea,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect,
        vSelect
    },
    data: () => ({
        required,
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
            {key: 'order', label: 'No'},
            {key: 'no', label: 'No. Procurement'},
            {key: 'tanggal', label: 'Tanggal'},
            {key: 'informasi.total_pembelian', label: 'Total Procurement'},
            {key: 'status', label: 'Status'},
            {key: 'actions', label: '#'}
        ],
        pengadaans: [],
        form: {
            gudang_id: null,
            id_supplier: null,
            tanggal: null,
            keterangan: null
        }
    }),
    methods: {
        submit() {
            this.$refs['form-pengadaan'].validate().then(async success => {
                if(success) {
                    const payload = this.form
                    if(this.id) {
                        payload.id = this.id
                    }
                    if(this.myGudang) {
                        payload.gudang_id = this.myGudang.id
                    }

                    try {
                        this.loading = true
                        const pengadaan = await this.$store.dispatch('asset/saveProcurement', [payload])
                        await this.getProcurement()
                        this.loading = false
                        if(this.id) {
                            this.$bvModal.hide('form-pengadaan')
                        }
                        else {
                            this.$router.push(`/asset-pengadaan/detail/${pengadaan.id}`)
                        }
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        edit(item) {
            this.id = item.id
            this.form.gudang_id = item.gudang_id
            this.form.id_supplier = item.id_supplier
            this.form.tanggal = item.tanggal
            this.form.keterangan = item.keterangan
            this.$bvModal.show('form-pengadaan')
        },
        remove(item)  {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data pengadaan ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(async res => {
                if(res.value) {
                    const payload = {
                        fungsi: 2,
                        id: item.id,
                        asset_id: item.asset_id
                    }

                    try {
                        this.loading = true
                        await this.$store.dispatch('asset/saveProcurement', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Data pengadaan berhasil dihapus'
                        })
                        this.getProcurement()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        resetForm() {
            this.id = null
            this.form.id = null
            this.form.gudang_id = null
            this.form.id_supplier = null
            this.form.tanggal = this.getCurrentDate()
            this.form.keterangan = null
        },
        add() {
            this.resetForm()
            this.$bvModal.show('form-pengadaan')
        },
        getVariant(item) {
            let result = 'danger'

            switch(item.status) {
                case 1:
                    result = 'info'
                break
                case 2:
                    result = 'dark'
                break
                case 3:
                    result = 'warning'
                break
                case 4:
                    result = 'dark'
                break
                case 5:
                    result = 'success'
                break
                default:
                    result = 'danger'
            }

            return result
        },
        async getProcurement() {
            this.loading = true
            const params = {order: 'desc'}
            if(this.myGudang) {
                params.gudang_id = this.myGudang.id
            }
            const pengadaans = await this.$store.dispatch('asset/getProcurement', params)
            const pendingProcurement = pengadaans.filter(item => item.status == 0) // for owner
            const approveOwnerProcurement = pengadaans.filter(item => item.status == 1) // for finance
            const approveFinanceProcurement = pengadaans.filter(item => item.status == 3) // for admin aset
            const otherProcurement = pengadaans.filter(item => [2, 4, 5].includes(item.status))
            this.pengadaans = [...pendingProcurement, ...approveOwnerProcurement, ...approveFinanceProcurement, ...otherProcurement]
            this.pengadaans.map(item => item.status_variant = this.getVariant(item))
            if(this.isAdminAsset) {
                this.pengadaans = [...approveFinanceProcurement, ...pendingProcurement, ...approveOwnerProcurement, ...otherProcurement]
            }
            else if(this.isFinance) {
                this.pengadaans = [...approveOwnerProcurement, ...approveFinanceProcurement, ...pendingProcurement, ...otherProcurement]
            }
            this.totalRows = this.pengadaans.length
            this.loading = false
        },
        detail(item) {
            this.$router.push(`/pengadaan/detail/${item.id}`)
        },
        async getSupplier() {
            const params = this.isOwner  || isOwnerHeykama ? {jenis: 1} : {jenis: 2}
            const suppliers = await this.$store.dispatch('supplier/getData', params)
            this.suppliers = suppliers.map(item => ({value: item.id, text: item.instansi}))
        }
    },
    created() {
        this.form.tanggal = this.getCurrentDate()
        this.getProcurement()
        this.getSupplier()
    }
}
</script>